import React from "react";
import "./araclar.css";
import carModel from "../../assets/car-model.png";
import gears from "../../assets/gears.png";
import palette from "../../assets/palette.png";
import SlideShow from "../../components/SlideShow";
import carBackground from "../../assets/logo.png";
import { carList } from "../../helper/car";

function Araclar() {
  return (
    <div className="d-flex flex-d-row justify-center full-w araclar">
      <div className="width-70 page-content">
        <div className="page-title">
          <h2>Araçlarımız</h2>
        </div>
        <div className="car-content">
          {carList.map((car) => {
            return (
              <div key={car.id} className="car-box">
                <div className="car-bg-container">
                  <img
                    className="car-background"
                    src={carBackground}
                    alt=""
                  ></img>
                </div>
                <div className="car-img-content">
                  <SlideShow images={car.resim} />
                </div>
                <div className="full-w">
                  <h3 className="car-title">
                    {car.marka} {car.model}
                  </h3>
                  <div className="d-flex align-center justify-s-around car-desc">
                    <div>
                      <img className="icon" src={carModel} alt="" /> {car.yil}
                    </div>
                    <div>
                      <img className="icon" src={gears} alt="" /> {car.vites}
                    </div>
                    <div>
                      <img className="icon" src={palette} alt="" />{" "}
                      <input type="color" value={car.renk} disabled />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div className="car-box">
            <div className="car-bg-container">
              <img className="car-background" src={carBackground} alt=""></img>
            </div>
            <div className="car-img-content">
              <SlideShow images={clio} />
            </div>
            <div className="full-w">
              <h3 className="car-title">Renault Clio Hatchbag</h3>
              <div className="d-flex align-center justify-s-around car-desc">
                <div>
                  <img className="icon" src={carModel} alt="" /> 2016
                </div>
                <div>
                  <img className="icon" src={gears} alt="" /> Manuel
                </div>
                <div>
                  <img className="icon" src={palette} alt="" />{" "}
                  <input type="color" value="#ff0000" disabled />
                </div>
              </div>
            </div>
          </div>
          <div className="car-box">
            <div className="car-bg-container">
              <img className="car-background" src={carBackground} alt=""></img>
            </div>
            <div className="car-img-content">
              <SlideShow images={accent} />
            </div>
            <div className="full-w">
              <h3 className="car-title">Hyundai Accent Blue</h3>
              <div className="d-flex align-center justify-s-around car-desc">
                <div>
                  <img className="icon" src={carModel} alt="" /> 2017
                </div>
                <div>
                  <img className="icon" src={gears} alt="" /> Manuel
                </div>
                <div>
                  <img className="icon" src={palette} alt="" />{" "}
                  <input type="color" value="#ADD8E6" disabled />
                </div>
              </div>
            </div>
          </div>
          <div className="car-box">
            <div className="car-bg-container">
              <img className="car-background" src={carBackground} alt=""></img>
            </div>
            <div className="car-img-content">
              <SlideShow images={fiorino} />
            </div>
            <div className="full-w">
              <h3 className="car-title">Fiat Fiorino</h3>
              <div className="d-flex align-center justify-s-around car-desc">
                <div>
                  <img className="icon" src={carModel} alt="" /> 2021
                </div>
                <div>
                  <img className="icon" src={gears} alt="" /> Manuel
                </div>
                <div>
                  <img className="icon" src={palette} alt="" />{" "}
                  <input type="color" value="#ffffff" disabled />
                </div>
              </div>
            </div>
          </div>
          <div className="car-box">
            <div className="car-bg-container">
              <img className="car-background" src={carBackground} alt=""></img>
            </div>
            <div className="car-img-content">
              <SlideShow images={albea} />
            </div>
            <div className="full-w">
              <h3 className="car-title">Fiat Albea Sole</h3>
              <div className="d-flex align-center justify-s-around car-desc">
                <div>
                  <img className="icon" src={carModel} alt="" /> 2011
                </div>
                <div>
                  <img className="icon" src={gears} alt="" /> Manuel
                </div>
                <div>
                  <img className="icon" src={palette} alt="" />{" "}
                  <input type="color" value="#545454" disabled />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Araclar;
