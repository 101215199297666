import clio1 from "../../src/assets/arabalar/clio/1.png";
import clio2 from "../../src/assets/arabalar/clio/2.png";
import clio3 from "../../src/assets/arabalar/clio/3.png";
import accent1 from "../../src/assets/arabalar/accentBlue/1.png";
import accent2 from "../../src/assets/arabalar/accentBlue/2.png";
import fiorino1 from "../../src/assets/arabalar/fiorino/1.png";
import fiorino2 from "../../src/assets/arabalar/fiorino/2.png";
import albea1 from "../../src/assets/arabalar/albea/1.png";
import albea2 from "../../src/assets/arabalar/albea/2.png";
import egea1 from "../../src/assets/arabalar/egea/1.png";
import egea2 from "../../src/assets/arabalar/egea/2.png";
import linea2 from "../../src/assets/arabalar/linea/2.png";
import linea1 from "../../src/assets/arabalar/linea/1.png";
import courier1 from "../../src/assets/arabalar/courier/1.png";
import courier2 from "../../src/assets/arabalar/courier/2.png";
import courier3 from "../../src/assets/arabalar/courier/3.png";
import fluence1 from "../../src/assets/arabalar/fluence/1.png";
import fluence2 from "../../src/assets/arabalar/fluence/2.png";
import elysee1 from "../../src/assets/arabalar/citroen/1.png";
import elysee2 from "../../src/assets/arabalar/citroen/2.png";
import symbol1 from "../../src/assets/arabalar/symbol/1.png";
import symbol2 from "../../src/assets/arabalar/symbol/2.png";
export const carList = [
  {
    id: 1,
    marka: "Renault",
    model: "Clio",
    yil: "2016",
    vites: "Manuel",
    renk: "#ff0000",
    resim: [clio1, clio2, clio3],
  },
  {
    id: 2,
    marka: "Hyundai",
    model: "Accent Blue",
    yil: "2017",
    vites: "Manuel",
    renk: "#ADD8E6",
    resim: [accent1, accent2],
  },
  {
    id: 3,
    marka: "Fiat",
    model: "Fiorino",
    yil: "2021",
    vites: "Manuel",
    renk: "#ffffff",
    resim: [fiorino1, fiorino2],
  },
  {
    id: 4,
    marka: "Fiat",
    model: "Albea Sole",
    yil: "2011",
    vites: "Manuel",
    renk: "#545454",
    resim: [albea1, albea2],
  },
  {
    id: 5,
    marka: "Fiat",
    model: "Egea",
    yil: "2020",
    vites: "Manuel",
    renk: "#ffffff",
    resim: [egea1, egea2],
  },
  {
    id: 6,
    marka: "Fiat",
    model: "Linea",
    yil: "2018",
    vites: "Manuel",
    renk: "#ffffff",
    resim: [linea1, linea2],
  },
  {
    id: 7,
    marka: "Ford",
    model: "Tourneo Courier",
    yil: "2018",
    vites: "Manuel",
    renk: "#ffffff",
    resim: [courier1, courier2, courier3],
  },
  {
    id: 8,
    marka: "Renault",
    model: "Fluence",
    yil: "2019",
    vites: "Otomatik",
    renk: "#dddddd",
    resim: [fluence1, fluence2],
  },
  {
    id: 9,
    marka: "Citroen",
    model: "C Elysee",
    yil: "2020",
    vites: "Manuel",
    renk: "#ffffff",
    resim: [elysee1, elysee2],
  },
  {
    id: 10,
    marka: "Renault",
    model: "Symbol",
    yil: "2021",
    vites: "Manuel",
    renk: "#ffffff",
    resim: [symbol1, symbol2],
  },
];
