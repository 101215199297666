import React, { useEffect } from "react";
import "./reservationForm.css";
import { carList } from "../../helper/car";
import axios from "axios";
import Inputmask from "inputmask";
import Swal from "sweetalert2";

function ReservationForm() {
  useEffect(() => {
    Inputmask({ mask: "(999) 999 9999" }).mask(
      document.getElementById("phone-number")
    );
  }, []);
  function sendReservation() {
    var formData = new FormData();
    let arac = document.getElementById("arac").value;
    let startDate = document.getElementById("startDate").value;
    let endDate = document.getElementById("endDate").value;
    let phoneNumber = document.getElementById("phone-number").value;
    formData.append("arac", arac);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("phoneNumber", phoneNumber);
    if (arac && startDate && endDate && phoneNumber) {
      axios
        .post(
          "https://api.erzincandanotokiralama.com/reservation-request/",
          formData
        )
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire("", "Rezervasyon talebiniz alınmıştır.", "success");
            document.getElementById("arac").value = "";
            document.getElementById("startDate").value = "";
            document.getElementById("endDate").value = "";
          } else {
            Swal.fire("", "Bir hata oluştu.", "error");
          }
        })
        .catch((err) => {
          Swal.fire("", "Bir hata oluştu.", "error");
        });
    } else {
      Swal.fire("", "Eksik alanları doldurunuz.", "error");
    }
  }
  return (
    <div className="reservation-form-area d-flex">
      <div className="d-flex flex-d-col full-w reservation-form-left-content">
        <label htmlFor="arac">Araç Seçiniz</label>
        <select
          className="form-select"
          placeholder="Araç Seçiniz"
          aria-label="Default select example"
          id="arac"
          name="arac"
          style={{ width: "100%" }}
        >
          <option disabled selected>
            {" "}
            Araç Seçiniz
          </option>
          {carList.map((car) => {
            return (
              <option value={car.model}>
                {car.marka} {car.model}
              </option>
            );
          })}
        </select>
        <div className="d-flex date-input-div">
          <div className="d-flex flex-d-col full-w">
            <label htmlFor="startDate">Başlangıç Tarihi</label>
            <input
              id="startDate"
              name="startDate"
              type="text"
              placeHolder="Tarih Seçiniz."
              onFocus={() => {
                document.getElementById("startDate").type = "date";
              }}
            ></input>
          </div>
          <div className="d-flex flex-d-col full-w">
            <label htmlFor="endDate">Teslim Tarihi</label>
            <input
              id="endDate"
              name="endDate"
              type="text"
              placeHolder="Tarih Seçiniz."
              onFocus={() => {
                document.getElementById("endDate").type = "date";
              }}
            ></input>
          </div>
        </div>
        <label htmlFor="phoneNumber">Telefon Numarası</label>
        <input
          id="phone-number"
          className="phone-number-input"
          type="text"
          placeholder="Telefon Numaranızı Giriniz"
        ></input>
        <div className="d-flex justify-center full-w">
          <button
            onClick={() => {
              sendReservation();
            }}
            className="reservation-submit-button"
          >
            <b>Rezervasyon Yap</b>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReservationForm;
