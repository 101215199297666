import axios from "axios";
import React from "react";

function Hakkimizda() {
  function databaseTest() {
    var formData = new FormData();
    formData.append("car_brand", "asdasd");
    formData.append("car_model", "asdasd");
    formData.append("car_year", "asdasd");
    axios
      .post("https://api.erzincandanotokiralama.com/get-car/", formData)
      .then((response) => {
        console.log(response);
      });
  }
  return (
    <div>
      <button
        onClick={() => {
          databaseTest();
        }}
      >
        test
      </button>
    </div>
  );
}

export default Hakkimizda;
