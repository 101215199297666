import React from "react";
import "./footer.css";
import logo from "../../assets/logo.png";
import instagram from "../../assets/instagram.png";
import phone from "../../assets/phone.png";
import home from "../../assets/gold-home.png";

function Footer() {
  return (
    <footer id="footer" className="d-flex full-w footer-content justify-center">
      <div className="width-70 d-grid footer-section">
        <div className="d-flex align-center footer-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="d-grid footer-desc">
          <div className="d-flex  footer-nav">
            <ul>
              <li>
                <a href="/anasayfa">Anasayfa</a>
              </li>
              <li>
                <a href="/araclar">Araçlar</a>
              </li>
              <li>
                <a href="/iletisim">İletişim</a>
              </li>
              <li>
                <a href="/hakkimizda">Hakkımızda</a>
              </li>
            </ul>
          </div>
          <div className="d-flex flex-d-col justify-center footer-social">
            <div className="d-flex align-center">
              <img className="icon" src={instagram} alt="instagram" />
              <a
                style={{ color: "gold", fontSize: "0.7rem" }}
                href="https://www.instagram.com/erzincandanotokiralama/"
                target="_blank"
                rel="noreferrer"
              >
                @erzincandanotokiralama
              </a>
            </div>
            <div className="d-flex align-center">
              <img className="icon" src={phone} alt="phone" />
              <a
                style={{ color: "gold", fontSize: "0.7rem" }}
                href="tel:+905427711949"
              >
                05427711949
              </a>
            </div>
            <div className="d-flex align-center">
              <img className="icon" src={phone} alt="phone" />
              <a
                style={{ color: "gold", fontSize: "0.7rem" }}
                href="tel:+905428454324"
              >
                05428454324
              </a>
            </div>
            <div className="d-flex align-center">
              <img className="icon" src={home} alt="home" />
              <p style={{ color: "gold", fontSize: "0.7rem" }}>
                İnönü mahallesi 6. sk Uzun çarşı kat 2 no 234
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
