import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/header";
import HomePage from "./pages/homePage";
import bg from "./assets/background.png";
import WhatsAppButton from "./components/WhatsAppButton";
import Araclar from "./pages/araclar";
import Iletisim from "./pages/iletisim";
import Footer from "./components/Footer";
import Hakkimizda from "./pages/hakkimizda";

function App() {
  return (
    <Router>
      <Header />
      <WhatsAppButton />
      <div
        style={{
          width: `${window.innerWidth}px`,
          height: `${window.innerHeight}px`,
        }}
        className="bg-container"
      >
        <img className="background" src={bg} alt="" />
      </div>
      <Routes>
        <Route path="/araclar" element={<Araclar />} />
        <Route path="/iletisim" element={<Iletisim />} />
        <Route path="/hakkimizda" element={<Hakkimizda />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
