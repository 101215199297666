import React from "react";
import "./whatsAppButton.css";
import whatsAppIcon from "../../assets/whatsapp.png";

function WhatsAppButton() {
  return (
    <div className="whatsApp-container">
      <a
        href="https://wa.me/+905427711949"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsAppIcon} class="whatsApp-icon" alt=""></img>
      </a>
    </div>
  );
}

export default WhatsAppButton;
