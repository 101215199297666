import React from "react";
import "./iletisim.css";
import locationIcon from "../../assets/location.png";
import phoneIcon from "../../assets/phone-call.png";
import emailIcon from "../../assets/email.png";

function Iletisim() {
  return (
    <div className="d-flex flex-d-row justify-center full-w iletisim">
      <div className="width-70 page-content">
        <div className="page-title">
          <h3>İletişim</h3>
        </div>
        <div className="d-grid flex-d-row justify-s-around contact-container">
          <ul className="d-flex flex-d-col justify-center">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/dir//ErzinCandan+Oto+Kiralama+%C4%B0n%C3%B6n%C3%BC+mahallesi+6.+sk+Uzun+%C3%A7ar%C5%9F%C4%B1+kat+2+no+234+24000+Merkez%2FErzincan/@39.7487138,39.4908747,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x40700d176672e70d:0x9b9470873e1afcc5"
              >
                <img className="icon" src={locationIcon} alt="" /> İnönü
                mahallesi 6. sk Uzun çarşı kat 2 no 234, 24000 Merkez/Erzincan
              </a>
            </li>
            <li>
              <div className="d-flex justify-s-between full-w">
                <a href="tel:+905427711949">
                  <img className="icon" src={phoneIcon} alt="" />
                  <b>Murat Salim Güner</b> <h5>+90 542 771 1949</h5>
                </a>
                <a href="tel:+905428454324">
                  <img className="icon" src={phoneIcon} alt="" />
                  <b>Samet Batuhan Candan</b> <h5>+90 542 845 4324</h5>
                </a>
              </div>
            </li>
            <li>
              <a href="mailto:info@erzincandanotokiralama.com">
                <img className="icon" src={emailIcon} alt="" />
                info@erzincandanotokiralama.com
              </a>
            </li>
          </ul>
          <div className="maps">
            <iframe
              title="maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.5963179152304!2d39.48639003168915!3d39.748717838193315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40700d176672e70d%3A0x9b9470873e1afcc5!2sErzinCandan%20Oto%20Kiralama!5e0!3m2!1str!2str!4v1660555277996!5m2!1str!2str"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Iletisim;
