import React from "react";
import "./header.css";
import logo from "../../assets/logo.png";
import menuIcon from "../../assets/menu-icon.png";
function Header() {
  return (
    <header>
      <div className="header-content">
        <div className="homeImage">
          <a href="/">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="desktop-header header-content-button">
          <a href="/araclar">Araçlar</a>
          <a href="/iletisim">İletişim</a>
          <a href="/hakkimizda">Hakkımızda</a>
        </div>
        {/* <input type="checkbox" className="d-none" id="mobileMenu" /> */}
        <div
          className="mobile-header d-none d-flex align-center"
          onClick={() => {
            document.getElementById("mobilmenu").style.width = "70%";
            document.getElementById(
              "mobilmenu"
            ).style.height = `${window.innerHeight}px`;

            document.getElementsByClassName(
              "outside-mobilemenu"
            )[0].style.width = `${window.innerWidth}px`;
            document.getElementsByClassName(
              "outside-mobilemenu"
            )[0].style.height = `${window.innerHeight}px`;
          }}
        >
          <img src={menuIcon} alt="menu-icon" />
        </div>
        <div className="mobile-sidemenu" id="mobilmenu">
          <ul>
            <li>
              <a href="/">Anasayfa</a>
            </li>
            <li>
              <a href="/araclar">Araçlar</a>
            </li>
            <li>
              <a href="/iletisim">İletişim</a>
            </li>
            <li>
              <a href="/hakkimizda">Hakkımızda</a>
            </li>
          </ul>
        </div>
        <div
          className="outside-mobilemenu"
          onClick={() => {
            document.getElementById("mobilmenu").style.width = "0px";
            document.getElementsByClassName(
              "outside-mobilemenu"
            )[0].style.width = "0px";
          }}
        />
      </div>
    </header>
  );
}

export default Header;
