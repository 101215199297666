import React from "react";
import { Zoom } from "react-slideshow-image";
import "./slideShow.css";

function SlideShow(props) {
  const zoomOutProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    scale: 0.4,
    arrows: false,
  };
  return (
    <div className="slide-container">
      <Zoom {...zoomOutProperties}>
        {props.images.map((each, index) => (
          <div
            key={index}
            className="each-slide"
            style={{ backgroundImage: `url(${props.background})` }}
          >
            <img className="slide-img" alt="" src={each} />
          </div>
        ))}
      </Zoom>
    </div>
  );
}

export default SlideShow;
