import communication from "../../src/assets/chat.png";
import confidence from "../../src/assets/confidence.png";
import airport from "../../src/assets/airport.png";
import cars from "../../src/assets/cars.png";
import feedback from "../../src/assets/feedback.png";
import add from "../../src/assets/add.png";
export const ourServices = [
  {
    id: 1,
    image: confidence,
    title: "Öncelik Güven",
    desc: "Tüm çalışmalarımız hukuğa uygundur. 7/24 ulaşılabilirliğimiz ile güveniniz bizim için önemlidir.",
  },
  {
    id: 2,
    image: communication,
    title: "7/24 İletişim",
    desc: "İstediğiniz zaman 7/24 ulaşılabilirlik sağlıyoruz.",
  },
  {
    id: 3,
    image: airport,
    title: "Havaalanı Desteği",
    desc: "Havaalanında da araçlarımızla yanınızdayız.",
  },
  {
    id: 4,
    image: cars,
    title: "Geniş Araç Filosu",
    desc: "Çeşitli ve geniş araç kadromuz ile hizmetinizdeyiz.",
  },
  {
    id: 5,
    image: add,
    title: "Filomuzu Genişletiyoruz",
    desc: "Filomuzu yenileyerek ve genişleterek kesintisiz hizmet vermeye devam ediyoruz.",
  },
  {
    id: 6,
    image: feedback,
    title: "Görüşleriniz Bizim İçin Değerli",
    desc: "<a style='text-decoration:underline' href='/iletisim'>İletişim</a> sayfamızdaki bilgilerimizle bize her zaman ulaşabilirsiniz. Öneri ve tavsiyelerinize açığız.",
  },
];
